import styled from 'styled-components';

const TagRow = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  cursor: pointer;
  align-items: center;
`;

export default TagRow;
