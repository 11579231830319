// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// event HOCs
import subscribeHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// react redux
import {connect} from 'react-redux';

// string lib
import sortByString from '@matthahn/sally-fw/lib/lib/sortByString';

// tag api
import createTagApi from '../../api/create.api.tag';
import deleteTagApi from '../../api/delete.api.tag';

// tag components
import SelectTagModal from '../../components/SelectTagModal/SelectTagModal';

// tag events
import multipleTagsDeletedEvent from '../../events/multipleDeleted.event.tag';
import showSelectTagModalEvent from '../../events/showSelectTagModal.event.tag';
import tagCreatedEvent from '../../events/created.event.tag';
import tagSelectedEvent from '../../events/selected.event.tag';

// tag redux actions
import {set as setAction} from '../../redux/actions';

class SelectTagContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    subscribe: PropTypes.func,
    tags: PropTypes.array,
  };

  static INITIAL_STATE = {
    color: '',
    deleting: false,
    excludeTags: [],
    label: '',
    saving: false,
    search: '',
    showCreateView: false,
    showDeleteConfirmationView: false,
    tags: [],
    ticket: null,
    visible: false,
  };

  state = {
    ...this.constructor.INITIAL_STATE,
  };

  componentDidMount() {
    this.props.subscribe(showSelectTagModalEvent.subscribe(this.show));
  }

  show = ({excludeTags = [], ticket = null} = {}) => {
    this.setState({
      ...this.constructor.INITIAL_STATE,
      excludeTags,
      ticket,
      visible: true,
    });
  };

  hide = () => {
    if (this.state.saving) return;
    this.setState({visible: false});
  };

  change = (key) => (value) => {
    this.setState({[key]: value});
  };

  select = (tag) => () => {
    const {saving, tags} = this.state;
    if (saving) return;
    const tagExists = !![...tags].find(({id}) => id === tag.id);
    const updatedTags = tagExists
      ? [...tags].filter(({id}) => id !== tag.id)
      : [...tags, tag];
    this.setState({tags: updatedTags});
  };

  confirmSelected = () => {
    const {saving, tags, ticket} = this.state;
    if (saving || !tags.length) return;
    this.setState({visible: false});
    tagSelectedEvent.publish({tags: [...tags], ticket});
  };

  startDeleting = () => {
    const {saving, tags} = this.state;
    if (saving || !tags.length) return;
    this.setState({showDeleteConfirmationView: true});
  };

  stopDeleting = () => {
    const {saving, deleting} = this.state;
    if (saving || deleting) return;
    this.setState({showDeleteConfirmationView: false});
  };

  delete = async () => {
    const {dispatch, tags} = this.props;
    const {saving, deleting, tags: selectedTags} = this.state;

    if (saving || deleting) return;

    this.setState({deleting: true});

    try {
      await Promise.all([...selectedTags].map(({id}) => deleteTagApi(id)));
      multipleTagsDeletedEvent.publish({tags: [...selectedTags]});
      const updatedTags = [...tags].filter(
        (tag) => ![...selectedTags].find(({id}) => id === tag.id)
      );
      dispatch(setAction({tags: updatedTags}));
      this.setState({deleting: false, visible: false});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({deleting: false});
    }
  };

  search = (search) => {
    this.setState({search});
  };

  save = async () => {
    const {dispatch, tags} = this.props;
    const {label, color, ticket} = this.state;

    if (!label || !color) return alert.warning('Fill out all fields.');

    this.setState({saving: true});

    try {
      const tag = await createTagApi({label, color});
      tagCreatedEvent.publish(tag);
      const updatedTags = [...tags, tag];
      dispatch(setAction({tags: updatedTags}));
      tagSelectedEvent.publish({tags: [tag], ticket});
      this.setState({saving: false, visible: false});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({saving: false});
    }
  };

  showCreateView = () => {
    this.setState({showCreateView: true});
  };

  hideCreateView = () => {
    if (this.state.saving) return;
    this.setState({showCreateView: false});
  };

  tags = () => {
    const {tags} = this.props;
    const {search, excludeTags} = this.state;
    const filteredTags = !!excludeTags.length
      ? [...tags].filter((tag) => !excludeTags.includes(tag.id))
      : [...tags];
    const searchedTags = !!search.trim().length
      ? [...filteredTags].filter((tag) =>
          tag.label.toLowerCase().includes(search.toLowerCase())
        )
      : [...filteredTags];
    return searchedTags.sort(sortByString({key: 'label'}));
  };

  render() {
    const {
      color,
      deleting,
      label,
      saving,
      search,
      showCreateView,
      showDeleteConfirmationView,
      tags,
      visible,
    } = this.state;
    return (
      <SelectTagModal
        color={color}
        deleting={deleting}
        label={label}
        onChange={this.change}
        onConfirmSelect={this.confirmSelected}
        onDelete={this.delete}
        onHide={this.hide}
        onHideCreateView={this.hideCreateView}
        onSave={this.save}
        onSearch={this.search}
        onSelect={this.select}
        onShowCreateView={this.showCreateView}
        onStartDeleting={this.startDeleting}
        onStopDeleting={this.stopDeleting}
        saving={saving}
        search={search}
        selectedTags={tags}
        showCreateView={showCreateView}
        showDeleteConfirmationView={showDeleteConfirmationView}
        tags={this.tags()}
        visible={visible}
      />
    );
  }
}

export default connect((state) => ({tags: state.tag.tags}))(
  subscribeHOC(SelectTagContainer)
);
