// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// lib
import fkOrId from '@matthahn/sally-fw/lib/lib/fkOrId';

// mechanic events
import selectCreatedMechanicEvent from '../../../mechanic/events/selectCreated.event.mechanic';
import showCreateMechanicModalEvent from '../../../mechanic/events/showCreateModal.event.mechanic';

// mechanic lib
import getBranchMechanics from '../../../mechanic/lib/getBranchMechanics.lib.mechanic';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// redux
import {connect} from 'react-redux';

// ticket api
import updateTicketApi from '../../api/update.api.ticket';

// ticket components
import TicketMechanicsModal from '../../components/TicketMechanicsModal/TicketMechanicsModal';

// ticket events
import showSelectMechanicModalEvent from '../../events/showSelectMechanicModal.event.ticket';

// ticket permissions
import updateTicketPermission from '../../permissions/update.permission.ticket';

class TicketMechanicsContainer extends Component {
  static propTypes = {
    mechanics: PropTypes.array,
    subscribe: PropTypes.func,
    syncTicket: PropTypes.func,
    ticket: PropTypes.object,
  };

  state = {
    visible: false,
  };

  componentDidMount() {
    this.mounted = true;
    this.props.subscribe(
      showSelectMechanicModalEvent.subscribe(this.show),
      selectCreatedMechanicEvent.subscribe(this.selectCreatedMechanic)
    );
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  show = () => {
    const {ticket} = this.props;
    if (ticket.approved) return;
    this.setState({visible: true});
  };

  hide = () => {
    this.setState({visible: false});
  };

  selectCreatedMechanic = async ({mechanic}) => {
    const {ticket} = this.props;
    if (!ticket || ticket.approved || !updateTicketPermission()) return;
    this.saveTicket(mechanic.id);
  };

  saveTicket = async (mechanicId) => {
    const {ticket, syncTicket} = this.props;
    if (!updateTicketPermission()) return;

    const updatedTicket = {...ticket, mechanic: mechanicId};
    syncTicket(updatedTicket);

    this.setState({visible: false});

    try {
      await updateTicketApi(ticket.id, {mechanic: mechanicId});
    } catch (error) {
      if (!this.mounted || this.props.ticket.id !== ticket.id) return;
      syncTicket(ticket);
    }
  };

  mechanics = () => {
    const {ticket, mechanics} = this.props;
    const branchId =
      fkOrId(ticket?.vehicle?.branch) || fkOrId(ticket?.branch) || null;
    return getBranchMechanics({mechanics, branchId});
  };

  createMechanic = () => {
    this.hide();
    showCreateMechanicModalEvent.publish({selectCreated: true});
  };

  render() {
    const {ticket} = this.props;
    const {visible} = this.state;
    return (
      <TicketMechanicsModal
        mechanic={ticket.mechanic}
        mechanics={this.mechanics()}
        onClose={this.hide}
        onCreateMechanic={this.createMechanic}
        onSelect={this.saveTicket}
        visible={visible}
      />
    );
  }
}

export default subscriptionHOC(
  connect((state) => ({
    mechanics: state.mechanic.mechanics,
  }))(TicketMechanicsContainer)
);
