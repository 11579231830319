import styled from 'styled-components';

const Tag = styled.div`
  padding: 4px;
  border-radius: 4px;
  font-size: 14px;
  color: ${({color}) => color || '#fff'};
  font-weight: 500;
  background: ${({theme}) => theme};
  cursor: pointer;
`;

export default Tag;
