// c0lor picker
import {SketchPicker} from 'react-color';

// components
import CheckBox from '@matthahn/sally-ui/lib/components/CheckBox/CheckBox';
import Column from '@matthahn/sally-ui/lib/components/Grid/Column/Column';
import Input from '@matthahn/sally-ui/lib/components/Input/Input';
import Modal from '@matthahn/sally-ui/lib/components/Modal/Modal';
import Row from '@matthahn/sally-ui/lib/components/Grid/Row/Row';

// local components
import Container from './components/Container';
import Tag from './components/Tag';
import TagRow from './components/TagRow';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Fragment} from 'react';

const SelectTagModal = ({
  color,
  deleting,
  label,
  onChange,
  onConfirmSelect,
  onDelete,
  onHide,
  onHideCreateView,
  onSave,
  onSearch,
  onSelect,
  onShowCreateView,
  onStartDeleting,
  onStopDeleting,
  saving,
  search,
  selectedTags,
  showCreateView,
  showDeleteConfirmationView,
  tags,
  visible,
}) => (
  <Modal
    visible={visible}
    title="Select Tag"
    onClose={onHide}
    size="small"
    headerActions={
      showCreateView
        ? []
        : [{icon: 'plus', tooltip: 'Create Tag', onClick: onShowCreateView}]
    }
    buttonsLeft={
      showDeleteConfirmationView
        ? [
            {
              label: 'Cancel',
              onClick: onStopDeleting,
              disabled: deleting,
            },
          ]
        : showCreateView
        ? [{label: 'Cancel', onClick: onHideCreateView, disabled: saving}]
        : [
            {
              label: 'Delete Selected',
              onClick: onStartDeleting,
              disabled: saving,
            },
          ]
    }
    buttonsRight={
      showDeleteConfirmationView
        ? [
            {
              label: 'Delete',
              onClick: onDelete,
              loading: deleting,
              theme: 'red',
            },
          ]
        : showCreateView
        ? [{label: 'Save', onClick: onSave, loading: saving, theme: 'black'}]
        : [{label: 'Select', onClick: onConfirmSelect, theme: 'black'}]
    }
  >
    {(Content) =>
      showDeleteConfirmationView ? (
        <Fragment>
          <Content>
            Are you sure you want to delete the selected tags?{' '}
            <b>This will completely remove the tags from Samson.</b>
            <br />
            <br />
            Selected Tags:
          </Content>
          <Content>
            <Container>
              {[...selectedTags].map((tag) => (
                <TagRow key={tag.id}>
                  <Tag theme={tag.color}>{tag.label}</Tag>
                </TagRow>
              ))}
            </Container>
          </Content>
        </Fragment>
      ) : showCreateView ? (
        <Content>
          <Row margin>
            <Column>
              <Input
                value={label}
                onChange={onChange('label')}
                disabled={saving}
              >
                Label
              </Input>
            </Column>
          </Row>
          <Row>
            <Column>
              <SketchPicker
                color={color}
                onChange={({hex}) => onChange('color')(hex)}
              />
            </Column>
          </Row>
        </Content>
      ) : (
        <Fragment>
          <Content>
            <Input value={search} onChange={onSearch} placeholder="Search..." />
          </Content>
          <Content>
            <Container>
              {tags.map((tag) => (
                <TagRow key={tag.id} onClick={onSelect(tag)}>
                  <CheckBox
                    value={[...selectedTags].find(({id}) => tag.id === id)}
                    onChange={() => {}}
                    size="small"
                  />
                  <Tag theme={tag.color}>{tag.label}</Tag>
                </TagRow>
              ))}
              {!tags.length && 'No tags found.'}
            </Container>
          </Content>
        </Fragment>
      )
    }
  </Modal>
);

SelectTagModal.propTypes = {
  color: PropTypes.string,
  deleting: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onConfirmSelect: PropTypes.func,
  onDelete: PropTypes.func,
  onHide: PropTypes.func,
  onHideCreateView: PropTypes.func,
  onSave: PropTypes.func,
  onSearch: PropTypes.func,
  onSelect: PropTypes.func,
  onShowCreateView: PropTypes.func,
  onStartDeleting: PropTypes.func,
  onStopDeleting: PropTypes.func,
  saving: PropTypes.bool,
  search: PropTypes.string,
  selectedTags: PropTypes.array,
  showCreateView: PropTypes.bool,
  showDeleteConfirmationView: PropTypes.bool,
  tags: PropTypes.array,
  visible: PropTypes.bool,
};

export default SelectTagModal;
